import { AbcComponent } from './abc/abc.component';
import { XyzComponent } from './xyz/xyz.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  { path: 'xyz', component: XyzComponent },
  {
    path: 'abc',
    component: AbcComponent,
  },
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
